import React from "react"
import logo from "../images/julmeme.svg"
import instagram from "../images/instagram.svg"

import seoThumbnail from "../images/share.jpg"
import SEO from "../components/seo"

const IndexPage = () => (
<div className="home font-walsheim">
  <SEO title="Swiss Graphic Designer" keywords={['graphic',  'design', 'branding', 'art', 'typography', 'swiss', 'tokyo', 'japan']} thumbnail={seoThumbnail} />
  <svg className="absolute pin-l pin-t w-64 p-4">
    <use xlinkHref={`#${logo.id}`} />
  </svg>
  <div className="bg-black min-h-screen w-full flex flex-wrap content-center items-center justify-center text-center">
    <div className="w-full sm:w-1/2 p-4 leading-loose show-font transition-property-opacity transition-fast">
      <p className="text-white">New website coming soon.</p>
      <p><a className="text-grey hover:text-white no-underline transition-property-color transition-fast " href="mailto:info@julmeme.com">info@julmeme.com</a></p>
      <a className="inline-block p-2 text-grey hover:text-white transition-property-color transition-fast" href="https://www.instagram.com/julmeme/" target="_blank" rel="noopener noreferrer">
        <svg className="w-8 h-8 fill-current">
          <use xlinkHref={`#${instagram.id}`} />
        </svg>
      </a>
    </div>
  </div>
</div>
)

export default IndexPage
